<template>
  <div class="ccc">
    <TopHead :isMessage="true"></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div class="box news">
          <div class="boxname">
            <h1><span></span>新闻公告</h1>
          </div>
          <div class="search">
            <span>新闻标题</span><input type="text" v-model="pageInfo.data.title"/><a @click="search()">查询</a>
          </div>
          <div class="content">
            <li v-for="(item,index) in noticeArr" :key="index">
              <a href="javascript:;" @click="goMessage(item,1)"
                 rel="noopener noreferrer"><span>{{item.title}}</span><span>{{item.writtenTime ? item.writtenTime.split(' ')[0] : ''}}</span></a>
            </li>
          </div>
          <div class="pagebox t_r">

            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-model:currentPage="pageInfo.pageIndex"
              :page-sizes="$global.pageArr"
              :page-size="pageInfo.pageSize"
              layout="sizes, prev, pager, next"
              :total="total"
            ></el-pagination>
          </div>
        </div>
        <div class="box knowledge">
          <div class="boxname">
            <h1><span></span>知识库</h1>
          </div>
          <div class="content">
            <a v-for="(item,index) in zsArr" :key="index" @click="goMessage(item)" target="_blank" rel="noopener noreferrer"><img v-if="item.fileDataList.length" :src="$global.host+'/uploadFile/downloadFile/'+item.fileDataList[0].fileId" :alt="item.title" /> </a>
            <!--<a v-for="(item,index) in zsArr" :key="index" @click="goMessage(item)" target="_blank" rel="noopener noreferrer"><img v-if="item.fileDataList.length" :src="$global.host+'/uploadFile/downloadFile/'+item.fileDataList[0].fileId" :alt="item.title" /> </a>-->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "message",
    data() {
      return {
        total:0,
        pageInfo:{
          pageIndex:1,
          pageSize:10,
          data:{title:'',newsClassificationId:'3798776'}
        },
        noticeArr:[],
        zsArr:[],
      }
    },
    created() {
      this.getNotice();
      this.getNotice(1);
    },
    methods: {
      handleSizeChange(val) {
        this.pageInfo.pageSize = val;
        this.search();
      },
      handleCurrentChange(val) {
        this.pageInfo.pageIndex = val;
        this.getNotice();
      },
      search(){
        this.pageInfo.pageIndex = 1;
        this.getNotice();
      },
      //获取通知公告
      getNotice(num){
        let send = this.pageInfo;
        // send.data = JSON.stringify(send.data);
        if(num){
          this.pageInfo.pageIndex = 1;
          this.pageInfo.data.newsClassificationId = '3798782';
        }else{
          this.pageInfo.data.newsClassificationId = '3798776';
        }
        this.$api.queryPage(send).then(res => {
          if(num){
            this.zsArr = res.data.rows;
          }else{
            this.noticeArr = res.data.rows;
          }
          this.total = res.data.recordCount;
        })
      },
      goMessage(row,num){
        let routeUrl = this.$router.resolve({
          path:'/messageDetails',
          query:{
            newsId:row.newsId,
            type: num ? 'message' : '',
          }
        });
        window.open(routeUrl.href, '_blank');
      },
    }
  }
</script>

<style scoped>
  .main .box{
    width: calc((100% - 470px));
    display: inline-block;
    vertical-align: top;
    background: #fff;
    height: 618px;
    margin: 20px 0 10px 0;
    border-radius: 10px;
  }
  .main .knowledge{
    width: 460px;
    margin-left: 10px;
    background: url(../assets/images/bg-knowledge.png) no-repeat right bottom #fff;
  }
  .main .boxname{
    height: 56px;
    padding: 0 20px;
  }
  .main .boxname h1{
    font-size: 20px;
    text-align: left;
    line-height: 56px;
  }
  .main .boxname h1 span{
    display: inline-block;
    width: 4px;
    vertical-align: middle;
    background: #1cd0bd;
    height: 20px;
    margin: -3px 10px 0 0;
  }
  .main .search{
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    text-align: left;
  }
  .main .search span{
    font-size: 14px;
    display: inline-block;
    text-indent: 10px;
  }
  .main .search input{
    width: 255px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-left: 15px;
    padding: 0 5px;
    box-sizing: border-box;
  }
  .main .search a{
    padding: 0 10px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    vertical-align: top;
    margin-left: 30px;
    border-radius: 3px;
    background: #1cd0bd;
    color: #fff;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  .main .search a:hover{
    box-shadow: 0 0 5px #ccc;
  }
  .main .news .content{
    height: 420px;
    margin: 25px 20px 0 20px;
    padding: 10px 0;
    border-top: 4px solid #f0f1f3;
  }

  .main .news .content li{
    font-size: 14px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    list-style: inside;
    transition: all 0.25s ease 0.1s;
  }
  .main .news .content li:hover,.main .news .content li a:hover{
    color: #1cd0bd;
  }
  .main .news .content li span{
    display: inline-block;
  }
  .main .news .content li span:first-of-type{
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  .main .news .content li span:last-of-type{
    width: 80px;
    text-align: right;
  }
  .main .knowledge .content{
    padding: 0px 0px 20px 0;
    text-align: left;
  }

  .main .knowledge .content a{
    display: inline-block;
    margin: 20px 0 10px 60px;
  }

  .main .knowledge .content a:hover{
    transform: scale(1.1);
  }

  .main .knowledge .content a.icon-notes{
    background: url("../assets/images/icon.png") no-repeat 0 -240px;
  }

  .main .knowledge .content a.icon-traffic{
    background: url("../assets/images/icon.png") no-repeat -155px -240px;
  }

  .main .knowledge .content a.icon-favoured{
    background: url("../assets/images/icon.png") no-repeat -313px -240px;
  }

  .main .knowledge .content a.icon-starttime{
    background: url("../assets/images/icon.png") no-repeat -470px -240px;
  }
  .main .loading{
    padding: 100px 0;
  }
</style>
